import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import TransitInfo from '../components/transit-info';
import { PricingTable } from '../components/pricing-table';
import { AdUnit } from '../components/adunit';
import { Event } from '../components/event';
import { DateBadge } from '../components/date-badge';
import { StartingPrice } from '../components/starting-price';
import { useArticleProgress } from '../hooks/use-article-progress';

import fixAssetUrl from '../fix-asset-url';
import formatRichText from '../format-rich-text';

import {
  getFirstTransitColor,
  getRandomHeroImageByCategory,
  copyToClipboard,
} from '../utils';
import { getDisplayDate } from '../utils/events';

export default ({ data, pageContext }) => {
  const { contentfulEvent, contentfulHomePage } = data;
  const { eventsPageAdUnit: adUnit } = data.contentfulHomePage;

  let moreEvents = data.moreEvents?.nodes?.filter(event => {
    const now = new Date();
    const endDate = new Date(event.endDateISO);
    if (event.categories && event.subcategories && endDate > now) {
      return true;
    }
    return false;
  });
  moreEvents = moreEvents?.slice(0, 2);

  useArticleProgress();

  const {
    slug,
    title,
    photo,
    photoCredit,
    categories,
    subcategories,
    description,
    transit,
    pricing,
    pricingTable,
    purchaseLink,
    address,
    directions,
    shortDate,
    shortEndDate,
    time,
    location,
    dateISO,
    endDateISO,
  } = contentfulEvent;

  const url = `https://away.mta.info/events/${slug}`;
  const transitColor = getFirstTransitColor(transit);
  const displayDate = getDisplayDate(dateISO, endDateISO);
  const image = photo
    ? photo
    : getRandomHeroImageByCategory(categories, contentfulHomePage.categories);

  return (
    <div className="event">
      <Layout
        title={title}
        canonicalUrl={url}
        image={fixAssetUrl(image.fixed.src)}
        mainClass="article-page"
        currentSection="events"
        backToLink="/events"
        backToLabel="Back to Events"
      >
        <article className={`event-details ${adUnit ? '' : 'no-border'}`}>
          <div className="article-header">
            <ul className="article__meta">
              {categories?.map(({ title }) => (
                <li key={title}>{title}</li>
              ))}
              {subcategories?.map(({ title }) => (
                <li key={title}>{title}</li>
              ))}
            </ul>
            <div className="article-header__title event-title">
              <h1>{title || 'Untitled Deal'}</h1>
              <div
                className="article-progress"
                style={{ color: getFirstTransitColor(transit) }}
              />
            </div>
            <div className="event__logistics">
              <DateBadge date={displayDate} color={transitColor} />
              <ul>
                {shortDate && (
                  <li>
                    {shortDate === shortEndDate
                      ? `${shortDate}  `
                      : `${shortDate} - ${shortEndDate}  `}
                    {`${time ? time : ''}`}
                  </li>
                )}
                <li>{location}</li>
                {pricingTable && (
                  <li>
                    <StartingPrice pricingTable={pricingTable} />
                  </li>
                )}
              </ul>
            </div>
            <div>
              <img
                className="article-header__image"
                alt={image.fixed.title}
                src={fixAssetUrl(image.fixed.src)}
                style={{ borderColor: getFirstTransitColor(transit) }}
              />
            </div>
            {photoCredit && (
              <div className="deal-photo-credit">
                <figcaption>{photoCredit}</figcaption>
              </div>
            )}
          </div>
          <div className="article-content">
            <div className="article-content-inner">
              <div className="event__description">
                {description && formatRichText(description.json)}
              </div>
              {pricing && formatRichText(pricing.json)}
              {pricingTable && <PricingTable pricingTable={pricingTable} />}
              {purchaseLink && (
                <a
                  href={purchaseLink}
                  rel="noreferrer"
                  target="_blank"
                  className="btn btn__get-tickets"
                >
                  More Information{' '}
                  <span className="material-icons md-18">open_in_new</span>
                </a>
              )}
              {address && (
                <>
                  <h2>Plan Your Trip</h2>
                  <div className="event__address-header">
                    <h3>Address</h3>
                    <button
                      type="button"
                      className="copy-address"
                      onClick={() => copyToClipboard(address.address)}
                    >
                      <span>Copy</span>
                      <span class="material-icons copy-icon">content_copy</span>
                    </button>
                  </div>
                  <div
                    className="event__address"
                    dangerouslySetInnerHTML={{
                      __html: address.childMarkdownRemark.html,
                    }}
                  />
                </>
              )}
              {directions && (
                <>
                  <h3>Directions</h3>
                  {formatRichText(directions.json)}
                </>
              )}
              <TransitInfo {...transit} />
            </div>
          </div>
          {moreEvents?.length > 0 && (
            <div className="related-events">
              <h2 className="related-events__title">More Events</h2>
              <ul className="event-list">
                {moreEvents.map(event => {
                  const newEvent = { ...event };
                  if (!event.photo) {
                    newEvent.photo = getRandomHeroImageByCategory(
                      event.categories,
                      contentfulHomePage.categories
                    );
                  }

                  return <Event key={event.id} event={event} />;
                })}
              </ul>
            </div>
          )}
        </article>
        <aside className="sidebar">
          <div className="sidebar-inner">
            {adUnit && <AdUnit ad={adUnit} />}
          </div>
        </aside>
      </Layout>
    </div>
  );
};

export const pageQuery = graphql`
  query EventBySlug($slug: String!) {
    contentfulEvent(slug: { eq: $slug }) {
      ...EventFragment
      photo {
        title
        fixed(width: 1180) {
          src
        }
      }
      categories {
        featuredImage {
          fluid(maxWidth: 1280, maxHeight: 717, resizingBehavior: FILL) {
            src
          }
          fixed(width: 1180) {
            src
          }
        }
      }
    }
    moreEvents: allContentfulEvent(filter: { slug: { ne: $slug } }) {
      nodes {
        ...EventListFragment
      }
    }
    contentfulHomePage {
      eventsPageAdUnit {
        ...AdUnitFragment
      }
      categories {
        slug
        featuredImage {
          fluid(maxWidth: 1280, maxHeight: 717, resizingBehavior: FILL) {
            src
          }
          fixed(width: 1180) {
            src
          }
        }
      }
    }
  }
`;
