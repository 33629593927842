import React from 'react';

import { getStartingPrice } from '../utils/events';

export const StartingPrice = ({ pricingTable }) => {
  const startingPrice = getStartingPrice(pricingTable);
  if (startingPrice) {
    return <>{`Starting at $${startingPrice}`}</>;
  }
  return null;
};
