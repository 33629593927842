import React from 'react';
import { Link } from 'gatsby';

import { DateBadge } from './date-badge';
import { StartingPrice } from './starting-price';

import { getFirstTransitColor } from '../utils';
import { getDisplayDate } from '../utils/events';

export const Event = ({ event }) => {
  const {
    slug,
    shortDate,
    shortEndDate,
    dateISO,
    endDateISO,
    title,
    time,
    location,
    categories,
    subcategories,
    transit,
    pricingTable,
  } = event;

  let tags = [];
  if (subcategories) {
    tags = [...subcategories];
  }
  if (categories) {
    tags = [...tags, ...categories];
  }

  const url = `/events/${slug}`;
  const transitColor = getFirstTransitColor(transit);
  const displayDate = getDisplayDate(dateISO, endDateISO);

  return (
    <li className="event">
      <Link to={url}>
        <div className="event__inner">
          <DateBadge date={displayDate} color={transitColor} />

          <div>
            <ul className="event__meta">
              {tags && tags.map(tag => <li key={tag.id}>{tag.title}</li>)}
            </ul>
            <h2 className="event__title">{title}</h2>
          </div>
          <ul className="event__details-list">
            <li>
              {shortDate === shortEndDate
                ? `${shortDate}`
                : `${shortDate} - ${shortEndDate}`}
              <br />
              {`${time ? ' · ' + time : ''}`}
            </li>
            <li>{location}</li>
            {pricingTable && (
              <li>
                <StartingPrice pricingTable={pricingTable} />
              </li>
            )}
          </ul>
        </div>
      </Link>
    </li>
  );
};
