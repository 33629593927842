import React from 'react';

export const PricingTable = ({ pricingTable }) => {
  return (
    <ul className="pricing-table">
      {pricingTable.map(p => {
        let [label, price] = p.split('|');
        label = label.trim();
        price = price.trim();
        return (
          <li key={p}>
            <span>{label}</span>
            <span>{price === '0' ? 'Free' : `$${price}`}</span>
          </li>
        );
      })}
    </ul>
  );
};
