import React from 'react';

export const DateBadge = ({ date, color }) => {
  const [month, day] = date.split(' ');
  return (
    <div className="date-badge" style={{ border: `0.5rem solid ${color}` }}>
      <span className="date-badge__month">{month}</span>
      <span className="date-badge__day">{day}</span>
    </div>
  );
};
